<template>
  <div class="pageMain" :class="globalDataArray.siteInfo.greyOut == 1 ? 'bigEventGray' : ''">
    <!-- 第二板块 -->

    <div class="des_new">
      <div class="w1440">
        <div class="e_container-1" v-if="contentInfo!=''&&contentInfo!=null">
          <div class="cbox-1-0">
            <div class="s_title0">
              <h1 class="e_h1-2 fnt_42">{{ contentInfo.data.title }}</h1>
              <div class="time">
                <img src="@/assets/images/new_time.png" alt="">
                <span class="fnt_18" v-if="contentInfo.data.public_time!=''&&contentInfo.data.public_time!=null">{{ contentInfo.data.public_time }}</span>
                <span class="fnt_18" v-else>{{ contentInfo.data.update_time }}</span>
              </div>
            </div>

            <div class="s_title fnt_16 ck-content" v-if="contentInfo.data.content!=''&&contentInfo.data.content!=null" v-html="contentInfo.data.content">
            </div>

            <div class="s_title fnt_16 ck-content" style="text-align:center;" v-else>{{ globalDataArray.siteTemplateLanguage.system_data_empty_tips }}</div>

            <div class="s_title1">
              <div class="cat_left">
                <p class="tle_p fnt_18" v-if="contentInfo.data.prev"><span class="cbox-21-0">{{ globalDataArray.siteTemplateLanguage.prev_news }}：</span><span class="cbox-21-1"><router-link :to="'/newsinfo?id='+contentInfo.data.prev.parentId">{{ contentInfo.data.prev.title }}</router-link></span></p>
                <p class="tle_p fnt_18" v-if="contentInfo.data.next"><span class="cbox-21-0">{{ globalDataArray.siteTemplateLanguage.next_news }}：</span><span class="cbox-21-1"><router-link :to="'/newsinfo?id='+contentInfo.data.next.parentId">{{ contentInfo.data.next.title }}</router-link></span></p>
              </div>
              <div class="cat_right">
                <div class="cbox-19-0 p_item">
                  <router-link v-if="fromQuery!=''&&fromQuery!=null" :to="'/news?from='+fromQuery" class="e_button-20 fnt_18">
                    <img src="@/assets/images/fhlb.png" alt="">
                    <span>{{ globalDataArray.siteTemplateLanguage.back_list }}</span>
                  </router-link>
                  <router-link v-else to="/news" class="e_button-20 fnt_18">
                    <img src="@/assets/images/fhlb.png" alt="">
                    <span>{{ globalDataArray.siteTemplateLanguage.back_list }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="cbox-1-1" v-if="contentInfo.data.pageRelatedList.records!=''&&contentInfo.data.pageRelatedList.records!=null">
            <div class="s_subtitle fnt_36">{{ globalDataArray.siteTemplateLanguage.info_list_news }}</div>
            <div class="p_item" v-if="contentInfo.data.pageRelatedList">
              <div class="e_loop-28">
                <div class="p_list" style="flex-direction:column;">
                  <template v-for="(item,index) in contentInfo.data.pageRelatedList.records">
                    <div class="e_image-27" :key="index" v-if="index==0"><router-link :to="'/newsinfo?id='+item.parentId"><img :src="item.image" alt=""></router-link></div>
                    <div class="cbox-28" :key="index">
                      <p class="fnt_18 e_text-30" v-if="item.publicTime!=''&&item.publicTime!=null">{{ item.publicTime }}</p>
                      <p class="fnt_18 e_text-30" v-else>{{ item.updateTime }}</p>
                      <p class="e_text-31 s_title fnt_20">
                        <router-link :to="'/newsinfo?id='+item.parentId">
                          {{ item.title }}
                        </router-link>
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from 'ant-design-vue'
import { getNewsDetail } from '@/api/news'
export default {
  name: 'Newsinfo',
  asyncData({ store, route }) {
    return store.dispatch('getNewsInfo', { id: route.query.id })
  },
  computed: {
    contentInfo() {
      return this.$store.state.newsInfo
    }
  },
  data() {
    return {
      newsinfoCont: '',
      fromQuery: ''
    }
  },
  mounted() {
    const that = this

    if (that.$store.state.newsInfo == null || that.$store.state.newsInfo == '') {
      message.error(that.globalDataArray.siteTemplateLanguage.system_data_empty_tips)
      let backPath = '/news'
      if (this.$route.query.from) {
        backPath = '/news?from=' + this.$route.query.from
      }

      this.$router.push({ path: backPath })
      return
    }

    if (typeof document === 'object') {
      document.documentElement.scrollTop = 0
    }

    if (this.$route.query.from) {
      this.fromQuery = this.$route.query.from
    }
  },
  methods: {

  },
  metaInfo() {
    let title = this.globalDataArray.siteInfo.title
    let description = this.globalDataArray.siteInfo.description
    let keywords = this.globalDataArray.siteInfo.keywords
    if (this.$store.state.newsInfo != '' && this.$store.state.newsInfo != null) {
      if (this.$store.state.newsInfo.data.title != '' && this.$store.state.newsInfo.data.title != null) {
        title = this.$store.state.newsInfo.data.title + '-' + this.globalDataArray.siteInfo.title
      }
      if (this.$store.state.newsInfo.data.description != '' && this.$store.state.newsInfo.data.description != null) {
        description = this.$store.state.newsInfo.data.description
      }
      if (this.$store.state.newsInfo.data.keywords != '' && this.$store.state.newsInfo.data.keywords != null) {
        keywords = this.$store.state.newsInfo.data.keywords
      }
    }
    return {
      title: title,
      meta: [
        { vmid: 'description', name: 'description', content: description },
        { vmid: 'keywords', name: 'keywords', content: keywords }
      ]
    }
  }
}
</script>
<style>
@import '@/assets/css/news_des.css'
</style>
